import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDfz0oNWWe3803_wW_OOe_gfOkzs7LTO0U",
    authDomain: "polskie-paulownie.firebaseapp.com",
    projectId: "polskie-paulownie",
    storageBucket: "polskie-paulownie.appspot.com",
    messagingSenderId: "709218367102",
    appId: "1:709218367102:web:972a6e4b23eac11bdb0a2f",
    measurementId: "G-B9D4DX8BHL"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const imageDb = getStorage(app)
export { db };
