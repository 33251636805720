import styled from "styled-components";

export const Wrapper = styled.section`
    margin-bottom: 32px;
    max-height: 540px;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: 40px 0;
`;

export const Video = styled.video`
    width: 100%;
    object-fit: contain;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        width: 140%;
    };
`;


