import styled, { css } from "styled-components";
import background from '../../common/Images/FAQImages/bg.jpg';
import { Link } from "react-scroll";
import { opacitySlide, slideBottom } from "../../core/animations";

export const Wrapper = styled.section`
    min-height: 100vh;
`;

export const Background = styled.img <{ right?: boolean, left?: boolean, bottom?: boolean }>`
    position: absolute;
    top: 40px;
    left: -10px;
    opacity: 0.1;
    max-width: 800px;
    z-index: -1;

    ${({ right }) => right && css`
        max-width: 800px;
        top: 2210px;
        right: 0px;
        left: auto;
        opacity: 0.1;
    `};
`;

export const Header = styled.header`
    width: 100%;
    height: 240px;
    background-image: url("${background}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 120px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        animation: ${slideBottom} 1s;
    };
    
`;

export const HeaderTitle = styled.h1`
    position: absolute;
    font-size: 100px;
    color: white;
    animation: ${slideBottom} 1s;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 56px;
    };
`;

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 52px;
    padding: 80px 20px;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        padding: 40px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
        padding: 40px 20px;
    };
`;


export const Navigation = styled.div`
    display: grid;
    position: sticky;
    z-index: 1; 
    box-shadow: 10px 10px 20px 20px #DDD;
    top: 140px;
    gap: 24px;
    padding: 40px;
    border-radius: 12px;
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: none;
    };
`;

export const NavLink = styled(Link)`
    cursor: pointer;
    font-size: 20px;
    text-transform: capitalize;
    color: #161717;
    transition: 0.3s;

    &:hover {
        opacity: 0.6;
    };
`;

export const Article = styled.article`
    display: grid;
    gap: 64px;
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 32px;
    };
`;

export const ArticleContent = styled.div`
    display: grid;
    gap: 12px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 8px;
    };
`;

export const ArticleTitle = styled.h2`
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    color: ${({ theme }) => theme.color.secondColor};
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 20px;
        letter-spacing: 1px;
        text-align: center;
    };
`;

export const ArticleText = styled.p`
    font-size: 24px;
    letter-spacing: -0.025ch;
    text-align: justify;
    line-height: 120%;
    color: rgb(109, 109, 109);
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;
