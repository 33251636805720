import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import { slideBottom, slideLeft } from "../../core/animations";

export const Wrapper = styled.section`
    margin: 40px 0;
`;

export const Form = styled.form`
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 40px;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    animation: ${slideLeft} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        animation: ${slideBottom} 1s;
        padding: 64px 40px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 20px 20px 64px;
    };
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 24px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
    };
`;

export const InputWrapper = styled.div`
    
    display: grid;
    gap: 10px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
    };
`;

export const XD = styled(PhoneInput)`
    background: white;
    border: none;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    font-size: 16px;
    font-family: 'Playfair Display', serif;
`;

export const Input = styled.input`
    background: white;
    border: none;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    font-size: 16px;
    font-family: 'Playfair Display', serif;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 10px;
        gap: 20px;
        font-size: 16px;
    };

    &:focus{
        outline-color: ${({ theme }) => theme.color.secondColor};
    }
`;

export const Label = styled.legend`
    color: ${({ theme }) => theme.color.secondColor};
`;

export const CheckBoxWrapper = styled.div`
    margin: 20px 0;
    display: grid;
    gap: 12px;
`;

export const CheckBoxLabel = styled.label`
    color: ${({ theme }) => theme.color.fontColor};
    font-size: 20px;
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 14px;
    };
`;

export const CheckBox = styled.input`
    padding: 10px;
    border-radius:20px;
    cursor: pointer;
    margin-right: 10px;


`;

export const Select = styled.select`
    background: white;
    border: none;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    font-size: 16px;
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 10px;
        gap: 20px;
        font-size: 16px;
    };

    &:focus{
        outline-color: ${({ theme }) => theme.color.secondColor};
    }
`;

export const Option = styled.option`
    padding: 10px;
    font-size: 16px;
    font-family: 'Playfair Display', serif;
`;

export const TextArea = styled.textarea`
    resize: none;
    width: 100%;
    font-size: 18px;
    margin: 0px 0 20px;
    background: transparent;
    padding: 12px;
    min-height: 140px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    border-radius: 10px;
    background: white;
    max-width: 700px;
    font-family: 'Playfair Display', serif;
    
    &:focus{
        outline-color: ${({ theme }) => theme.color.secondColor};
    }
`;

export const Button = styled.button`
    padding: 10px;
    max-width: 200px;
    width: 100%;
    font-size: 20px;
    color: ${({ theme }) => theme.color.mainColor};
    background: ${({ theme }) => theme.color.secondColor};
    border-radius: 8px;
    float:right ;
    cursor: pointer;
    transition: 0.3s;
    border: none;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
        max-width: 160px;
    };

    &:hover {
        background: ${({ theme }) => theme.color.hoverSecondColor};
        box-shadow: 0 0 6px ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        transform: none;
    };
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin: 10px 0 20px 0;
    padding: 0 10px 0 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 0;
    };
`;

export const InfoCheckBox = styled.input`
    cursor: pointer;
    margin-right: 10px;
`;

export const InformationSpan = styled.p`
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-family: Open Sans;
    margin: 0;
    text-align: justify;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 10px;
    };
`;