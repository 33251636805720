import {
    Button,
    CheckBox,
    Container,
    Form,
    InfoWrapper,
    InformationSpan,
    Input,
    InputWrapper,
    Label,
    Select,
    TextArea,
    Wrapper
} from './styledOrder';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Order = () => {

    const notifySuccess = () => toast.success("Twoja wiadomość została wysłana!");
    const notifyError = () => toast.error("Ups. Coś poszło nie tak spróbuj ponownie!");

    const handleSendEmailSuccess = () => {
        notifySuccess();
    };

    const handleSendEmailError = () => {
        notifyError();
    };

    const form = useRef<HTMLFormElement>(null);


    const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formElement = event.target as HTMLFormElement;

        emailjs.sendForm('service_154k192', 'template_emopuje', formElement, 'xFXcbRydqKtqjDlHZ')
            .then((result) => {
                if (result.text === "OK") {
                    formElement.reset();
                    handleSendEmailSuccess();
                }
            })
            .catch(() => {
                handleSendEmailError();
            });
    }

    return (
        <Wrapper>
            <Form onSubmit={sendEmail} ref={form}>
                <Container>
                    <InputWrapper>
                        <Label>Imię i Naziwsko *</Label>
                        <Input type='name' name="name" required />
                    </InputWrapper>

                    <InputWrapper>
                        <Label>Email *</Label>
                        <Input type='email' name="email" required />
                    </InputWrapper>

                    <InputWrapper>
                        <Label>Numer Telefonu *</Label>
                        <Input type='tel' name="number" required />
                    </InputWrapper>

                    <InputWrapper>
                        <Label>Wariant Drzewa *</Label>
                        <Select name="variant" required  >
                            <option disabled value=''>Wybierz wariant</option>
                            <option>Sadzonka</option>
                            <option>Drzewo</option>
                        </Select>
                    </InputWrapper>

                    <InputWrapper>
                        <Label>Wariant Odbioru *</Label>
                        <Select name="delivery" required  >
                            <option disabled value=''>Wybierz wariant</option>
                            <option>Dostawa</option>
                            <option>Odbiór osobisty</option>
                        </Select>
                    </InputWrapper>

                    <InputWrapper>
                        <Label>Ilość *</Label>
                        <Input type='number' min={1} name="quantity" required />
                    </InputWrapper>
                </Container>

                <InputWrapper>
                    <Label>Wiadomość </Label>
                    <TextArea name='message'></TextArea>
                </InputWrapper>

                <InfoWrapper>
                    <CheckBox name='checkbox' type='checkbox' required />
                    <InformationSpan>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych przez Polskie Paulownie, w celu otrzymania odpowiedzi na wiadomość zgodnie z Polityką Prywatności. Wiem, że zgodę tę mogę w każdej chwili cofnąć.
                    </InformationSpan>
                </InfoWrapper>
                <Button>Wyślij</Button>
            </Form>
        </Wrapper>
    );
};

