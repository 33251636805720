import styled, { css } from "styled-components";
import background from '../../common/Images/Plantation/bg.jpg';
import { opacitySlide, slideBottom,  slideRight } from "../../core/animations";

export const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1720px;
    margin: 0 auto;
    padding: 10px;
    overflow: hidden;
`;

export const Background = styled.img <{ right?: boolean, left?: boolean }>`
    position: absolute;
    top: 340px;
    left: 0px;
    opacity: 0.2;
    max-width: 1000px;
    z-index: -1;
`;

export const Header = styled.header`
    width: 100%;
    height: 240px;
    background-image: url("${background}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 120px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        animation: ${slideBottom} 1s;
    };
    
`;

export const HeaderTitle = styled.h1`
    position: absolute;
    font-size: 100px;
    color: white;
    animation: ${slideBottom} 1s;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 36px;
    };
`;

export const ContentWrapper = styled.div <{ reverse?: boolean }>`
    display: flex;
    align-items: center;
    padding: 40px 80px;
    gap: 40px;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 20px 10px;
    };

    ${({ reverse }) => reverse && css`
            flex-direction: row-reverse;
    `};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    };
`;

export const Text = styled.h3`
    font-size: 24px;
    margin: 10px;
    font-weight: normal;
    line-height: 1.2; 
    text-align: justify;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const Image = styled.img<{ reverse?: boolean }>`
    max-width: 660px;
    margin: 0 auto;
    width: 100%;
    animation: ${slideRight} 1s;
    border: 4px solid #0F7701;
    border-radius: 4px;
    box-shadow: 20px 20px 0px 0px #DDD;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        animation: ${slideBottom} 1s;
        max-width: 240px;
    };
`;

