import React from 'react';
import { Wrapper, TextWrapper, Logo,  ButtonLink,  Video } from './styledVideoSection';
import bgvideo from '../../../../common/videos/bg.mp4';

export const VideoSection = () => {
    return (
        <Wrapper>
            <Video src={bgvideo} autoPlay loop muted playsInline={true} />
            <TextWrapper>
                <Logo>Nasza plantacja</Logo>
                <ButtonLink to='/Nasza-plantacja'>Zobacz</ButtonLink>
            </TextWrapper>
        </Wrapper>
    );
};