import styled, { css } from "styled-components";
import { opacitySlide, slideBottom } from "../../../core/animations";
import bg from '../../../common/Images/BlogImages/bg.jpg';
import Slider from "react-slick";
import { Link } from "react-scroll";
import { Link as OfferLink } from "react-router-dom";

export const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1720px;
    margin: 0 auto;
    padding: 10px;
`;

export const Background = styled.img <{ right?: boolean, left?: boolean, bottom?: boolean }>`
    position: absolute;
    top: 40px;
    left: -10px;
    opacity: 0.15;
    max-width: 800px;
    z-index: -1;

    ${({ right }) => right && css`
        max-width: 400px;
        top: 440px;
        right: 0px;
        left: auto;
        opacity: 0.4;
    `};
`;

export const Header = styled.header`
    width: 100%;
    height: 240px;
    background-image: url("${bg}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 160px;
    };
`;

export const HeaderTitle = styled.h1`
    position: absolute;
    font-size: 80px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: white;
    animation: ${slideBottom} 1s;
    padding: 20px 40px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 80px;
        text-align: center;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 32px;
        padding: 20px;
        text-align:center;
    };
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 80px 40px;
    gap: 40px;
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
        padding: 40px 12px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        gap: 40px;
    };
`;

export const Navigation = styled.div`
    display: grid;
    gap: 24px;
    max-width: 520px;
    box-shadow: 10px 10px 20px 20px #DDD;
    padding: 40px;
    margin: 20px auto;
    border-radius: 12px;
    background: ${({ theme }) => theme.color.mainColor};
    animation: ${opacitySlide} 1s;


    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 12px;
        padding: 20px;
    };
`;

export const SectionTitle = styled.h3`
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    color: #787878;
    margin:0;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
      font-size: 24px;
    };
`;

export const LinksWrapper = styled.div`
    display: grid;
    gap: 20px;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 12px;
    };
`;

export const NavLink = styled(Link)`
    font-size: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondColor};
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;


export const ImageSection = styled.div`
    max-width: 640px;
    margin: 0 auto;
    position: sticky;
    top: 140px;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        top: 0;
        position: static;
    };
`;

export const CustomSlider = styled(Slider)`
    max-width: 540px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 20px 20px 0px 0px #DDD;
    border-radius: 20px ;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        max-width: 320px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        max-width: 240px;
    };
`;

export const Img = styled.img`
    width: 100%;
    position: sticky;
    top: 120px;
    border-radius: 20px ;
`;

export const LinkContainer = styled.div`
    display: grid;
    gap: 24px;
    max-width: 520px;
    box-shadow: 10px 10px 20px 20px #DDD;
    padding: 40px;
    margin: 20px auto;
    border-radius: 12px;
    background: ${({ theme }) => theme.color.mainColor};
    animation: ${opacitySlide} 1s;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 20px;
    };
`;

export const LinkButton = styled(OfferLink)`
    background: ${({ theme }) => theme.color.secondColor};
    color: white;
    padding: 10px 64px;
    margin: 0 auto;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        background: ${({ theme }) => theme.color.hoverSecondColor};
        box-shadow: 0 0 6px ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        transform: none;
    };
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const LinkSpan = styled.p`
    font-size: 24px;
    text-align: center;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 18px;
    };
`;

export const TextSection = styled.div`
    display: grid;
    gap: 52px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 32px;
    };
`;

export const TextTile = styled.div`

`;

export const ContentTitle = styled.h2`
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 4px;
    text-align: center;
    color: ${({ theme }) => theme.color.secondColor};
    margin: 0 0 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 20px;
        letter-spacing: 1px;
        margin: 0 0 10px;
    };
`;

export const Paragraph = styled.span`
    font-size: 24px;
    letter-spacing: -0.025ch;
    text-align: justify;
    line-height: 120%;
    color: rgb(109, 109, 109);
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

