import React from 'react';
import { Spinner, Title, Wrapper } from './styledLoader';
import spinner from '../../common/Images/LoaderImages/spinner.svg';

export const Loader = () => {
    return (
        <Wrapper>
            <Spinner src={spinner}  alt='spinner'/>
            <Title>
                Pobieranie wpisów...
            </Title>
        </Wrapper>
    );
};

