import styled from "styled-components";
import ReactQuill from 'react-quill';
import bg from '../../common/Images/GalleryImages/Images/GALERIA_004.jpg';

export const Wrapper = styled.section`
    min-height: 100vh;
    padding: 40px;
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
`;

export const Form = styled.form`
    box-shadow: 0 0 10px;
    border-radius: 10px;
    padding: 40px;
    display: grid;
    max-width: 800px;
    margin: 0 auto;
    gap: 28px;
    background: ${({ theme }) => theme.color.mainColor};
    box-shadow: 0 0 10px white;
`;

export const Input = styled.input`
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    background: none;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

export const PointWrapper = styled.div`
    display: grid;
    gap: 20px;
`;

export const Label = styled.label`
    display: grid;
    gap: 10px;
`;

export const Legend = styled.legend`
    font-size: 20px;
    text-align: center;
`;

export const TextEditor = styled(ReactQuill)`
    border: 1px solid ${({ theme }) => theme.color.thirdColor};

    .ql-container.ql-snow{
        border: none;
    }
`;

export const AddButton = styled.button`
    background: ${({ theme }) => theme.color.mainColor};
    color: ${({theme}) => theme.color.secondColor};
    font-weight: bold;
    padding: 12px 40px;
    border: 2px solid ${({theme}) => theme.color.secondColor};
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
    max-width: 240px;

    &:hover {
        background: ${({ theme }) => theme.color.secondColor};
        color: ${({theme}) => theme.color.mainColor};
    }
`;

export const Button = styled.button`
    margin: 0 auto;
    background: ${({ theme }) => theme.color.secondColor};
    color: white;
    padding: 12px 80px;
    border: none;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.color.hoverSecondColor};
    }
`;