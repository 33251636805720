import React from 'react';
import { Wrapper, Header, HeaderTitle, ContentWrapper, Navigation, Article, ArticleContent, ArticleTitle, ArticleText, NavLink, Background } from './styledFaq';
import { FAQLinks } from '../../core/arrays';
import bg from '../../common/Images/backgroundImages/PP_wektor_02.svg';
import bg2 from '../../common/Images/backgroundImages/PP_wektor_04.svg';

export const Faq = () => {
    return (
        <Wrapper>
            <Header>
                <HeaderTitle>FAQ</HeaderTitle>
            </Header>
            <Background src={bg}  alt='background'/>
            <Background right src={bg2} alt='background' />
            <ContentWrapper>
                <div>
                    <Navigation>
                        {FAQLinks.map((link, index) => (
                            <NavLink
                                key={index}
                                offset={-150}
                                spy={true}
                                to={link.to}
                                smooth={true}
                            >
                                {link.text}
                            </NavLink>
                        ))}
                    </Navigation>
                </div>

                <Article>
                    <ArticleContent id='1'>
                        <ArticleTitle>Czy drzewa tlenowe mogą być wykorzystywane w miejskich środowiskach?</ArticleTitle>
                        <ArticleText>
                            Oczywiście! Drzewa
                            tlenowe są często sadzone w miejskich środowiskach jako część programów zielonych miast, które mają
                            na celu poprawę jakości życia mieszkańców poprzez zwiększenie ilości zieleni. <b>Dlatego właśnie wielu
                                deweloperów decyduje się coraz częściej na sadzenie Paulowni w obrębie nowo wydudowanych
                                osiedli.</b> Ponieważ drzewa tlenowe rosną rekordowo szybko, w krótkim czasie możliwe jest pełne
                            zazielenienie nowo zabudowanych obszarów.
                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='2'>
                        <ArticleTitle>Czym tak właściwie jest drzewo tlenowe?</ArticleTitle>
                        <ArticleText>Nasza Paulownia odmiany Shan Tong to innowacyjne
                            drzewo, które jest wyjątkowo efektywne w pochłanianiu dwutlenku węgla. Jest to roślina która została
                            specjalnie zaprojektowana do szybkiego wzrostu i maksymalnej absorpcji CO2.
                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='3'>
                        <ArticleTitle>Jak szybko rośnie Paulownia?</ArticleTitle>
                        <ArticleText>Paulownia w odpowiednich warunkach może osiągnąć do 3 metrów
                            wzrostu rocznie, co czyni ją jednym z najszybciej rosnących drzew na świecie. Po osiągnięciu szczytowej
                            wysokości (około 10) metrów, Paulownia zatrzymuje wzrost.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='4'>
                        <ArticleTitle>Jakie są korzyści z uprawy drzew tlenowych?</ArticleTitle>
                        <ArticleText>Główną korzyścią z uprawy Paulowni jest jej zdolność
                            do pochłaniania dużej ilości CO2. Jedno drzewo może pochłonąć nawet do 20 razy więcej dwutlenku
                            węgla niż tradycyjne drzewa, co sprawia, że jest to ważne narzędzie w walce ze zmianami klimatycznymi.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Gdzie można uprawiać Paulownie?</ArticleTitle>
                        <ArticleText>W ogólnym skrócie – wszędzie. Paulownie mogą być uprawiane w
                            różnych klimatach, ale najlepiej rosną w umiarkowanych strefach klimatycznych o dużym
                            nasłonecznieniu i dostępie do wody. W Polsce zatem drzewa tlenowe przyjmują się nad wyraz dobrze i co
                            za tym idzie – stają się coraz popularniejsze.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy drzewo tlenowe może być wykorzystywane w celach komercyjnych?</ArticleTitle>
                        <ArticleText>Tak, Paulownia jest coraz
                            częściej wykorzystywana w celach komercyjnych, zwłaszcza jako alternatywna forma inwestycji
                            związana ze zmianami klimatycznymi i zrównoważonym rozwojem.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją jakieś ograniczenia w uprawie Paulowni?</ArticleTitle>
                        <ArticleText>Paulownia może wymagać regularnej opieki,
                            zwłaszcza podczas pierwszych lat wzrostu, aby zapewnić mu odpowiednie warunki do rozwoju.<b>Eksperci
                                w naszej firmie spędzili lata na badaniu wzrostu tych drzew, dlatego chętnie podzielą się z
                                Państwem swoim szerokim (już ponad dziesięcioletnim) doświadczeniem oraz odpowiedzą na
                                wszystkie pytania dotyczące sadzenia, opieki i hodowli Paulowni.</b> </ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją badania naukowe potwierdzające skuteczność Paulowni w pochłanianiu CO2?</ArticleTitle>
                        <ArticleText>Tak,
                            istnieją badania naukowe, które potwierdzają skuteczność drzew tlenowych w pochłanianiu CO2. Wiele
                            instytutów badawczych i uniwersytetów przeprowadziło badania nad tym tematem, co potwierdza
                            efektywność drzew tlenowych. Jednym z takich opracowań jest "PAULOWNIA – SZYBKO ROSNĄCE,
                            WIELOFUNKCYJNE DRZEWO BIOENERGETYCZNE" – przygotowane przez Małgorzatę Woźniak,
                            Annę Gałązkę i Magdalenę Frąc z <b>IUNG – Instytutu Uprawy Nawożenia i Gleboznawstwa w
                                Puławach.</b>  Elaborat dowodzi, że jedno drzewo redukuje 17kg CO2 w 3 lata.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją inne zastosowania drzew tlenowych poza absorpcją CO2?</ArticleTitle>
                        <ArticleText>Paulownia może być
                            wykorzystywana również w produkcji drewna oraz jako roślina ozdobna. Ponadto, jej szybki wzrost czyni
                            ją atrakcyjnym źródłem biomasy.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją projekty lub inicjatywy związane z uprawą Paulowni na dużą skalę?</ArticleTitle>
                        <ArticleText>Tak, istnieją różne
                            projekty i inicjatywy związane z uprawą drzew tlenowych na dużą skalę, zarówno na poziomie krajowym,
                            jak i międzynarodowym, które mają na celu wykorzystanie potencjału tego drzewa w walce ze zmianami
                            klimatycznymi.<b> Nasza firma w skali Polski należy do pionierów w tym segmencie.</b></ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Jakie są korzyści z posiadania drzew tlenowych?</ArticleTitle>
                        <ArticleText>Główną korzyścią z posiadania drzew tlenowych jest
                            ich zdolność do zwiększania poziomu tlenu w otoczeniu. Ponadto, pełnią one funkcję oczyszczania
                            powietrza z zanieczyszczeń, co przyczynia się do poprawy jakości życia.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją specjalne warunki, które należy zapewnić dla drzew tlenowych?</ArticleTitle>
                        <ArticleText>Drzewa tlenowe
                            potrzebują podobnych warunków do innych drzew, takich jak odpowiednia ilość światła słonecznego,
                            gleba o odpowiednim składzie i regularne podlewanie.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy drzewa tlenowe są łatwe w utrzymaniu?</ArticleTitle>
                        <ArticleText>Tak, drzewa tlenowe mogą być łatwe w utrzymaniu, pod
                            warunkiem zapewnienia im odpowiednich warunków wzrostu i regularnej opieki.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją badania naukowe potwierdzające korzyści zdrowotne z posiadania Paulowni?</ArticleTitle>
                        <ArticleText>Tak,
                            istnieją badania naukowe, które potwierdzają korzyści zdrowotne związane z obecnością drzew
                            tlenowych w otoczeniu, takie jak poprawa jakości powietrza i redukcja stresu.</ArticleText>
                    </ArticleContent>

                    <ArticleContent>
                        <ArticleTitle>Czy istnieją jakieś specjalne techniki sadzenia lub pielęgnacji drzew tlenowych?</ArticleTitle>
                        <ArticleText>Niektóre programy
                            sadzenia drzew tlenowych mogą korzystać z zaawansowanych technik sadzenia, takich jak systemy
                            nawadniania kroplowego lub stosowanie specjalnych podłoży do sadzenia, aby zapewnić najlepsze
                            warunki wzrostu dla drzew. <b>Nikogo jednak nie zostawiamy z tym samego – sprzedając Paulownie w
                                naszej firmie zawsze udzielamy kupującemu wszelkich niezbędnych informacji odnośnie
                                prawidłowej hodowli tych drzew – na każdym z jej etapów.</b></ArticleText>
                    </ArticleContent>
                </Article>
            </ContentWrapper>
        </Wrapper>
    );
};
