import styled, { css } from "styled-components";
import background from '../../common/Images/GalleryImages/Images/GALERIA_005.jpg'
import { opacitySlide, slideBottom } from "../../core/animations";
export const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1720px;
    margin: 0 auto;
    padding: 10px;
    overflow-x: hidden;
`;

export const Background = styled.img <{ right?: boolean, left?: boolean, bottom?: boolean }>`
    position: absolute;
    top: 40px;
    left: 0px;
    opacity: 0.15;
    max-width: 800px;
    z-index: -1;

    ${({ right }) => right && css`
        max-width: 400px;
        top: 740px;
        right: 0px;
        left: auto;
        opacity: 0.4;
    `};
`;

export const Header = styled.header`
    width: 100%;
    height: 240px;
    background-image: url("${background}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 160px;
    };
`;

export const HeaderTitle = styled.h1`
    position: absolute;
    stroke: 2px red;
    font-size: 100px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    padding: 10px;
    text-align: center;
    color: white;
    animation: ${slideBottom} 1s;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 80px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 36px;
    };
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: 64px auto;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        margin: 32px auto;
    };
`;

export const Title = styled.h2`
    text-align: center;
    font-size: 48px;
    color: ${({ theme }) => theme.color.secondColor};
    margin-bottom: 0px;
    animation: ${slideBottom} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 24px;
    };
`;