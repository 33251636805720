import React from 'react';
import { Article, ArticleContent, ArticleText, ArticleTitle, ContentWrapper, Header, HeaderTitle, Information, List, ListItem, NavLink, Navigation, Wrapper } from './styledPrivacy';
import { PrivacyLinks, browsers, coockies, declarations, extinsions, privacyData, rights } from '../../../core/arrays';

export const Privacy = () => {
    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Polityka Prywatności</HeaderTitle>
            </Header>
            <Information>Administratorem strony internetowej polskiepaulownie.pl jest Polskie Paulownie z/s w Ornontowicach. Dane kontaktowe: ul. Kolejowa 105, 43-178 Ornontowice.</Information>
            <ContentWrapper>
                <div>
                    <Navigation>
                        {PrivacyLinks.map((link, index) => (
                            <NavLink
                                key={index}
                                offset={-150}
                                spy={true}
                                to={link.to}
                                smooth={true}
                            >
                                {link.text}
                            </NavLink>
                        ))}
                    </Navigation>
                </div>


                <Article>
                    <ArticleContent id='declaration'>
                        <ArticleTitle>Deklaracja</ArticleTitle>
                        <ArticleText>Realizując nadrzędny cel jakim jest poszanowanie prywatności Odwiedzających stronę, staramy się dochować wszelkiej należytej staranności, aby sprostać temu założeniu, realizujemy normy i zasady wynikające z powszechnie obowiązujących przepisów prawa, w szczególności rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) oraz ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018 r. poz. 1000 z późn. zm.). </ArticleText>
                        <ArticleText> W szczególności, czynimy należyte starania, aby dane osobowe Odwiedzających stronę były:</ArticleText>
                        <List>
                            {declarations.map((declaration: string, index: number) => (
                                <ListItem key={index}>{declaration}</ListItem>
                            ))}
                        </List>

                        <ArticleText>
                            W ramach strony internetowej polskiepaulownie.pl Administrator gromadzi dane przekazywane przez Odwiedzających stronę oraz dane, które są zapisywane automatycznie. Dane te są wykorzystywane wyłączenie w celach określonych w niniejszej Polityce.
                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='data'>
                        <ArticleTitle>
                            Dane przekazywane przez Odwiedzających stronę
                        </ArticleTitle>
                        <ArticleText>
                            Do danych tych należy nazwa np. imię i nazwisko, adres e-mail oraz nr telefonu, a także inne dane podane przez Odwiedzającego stronę w przekazywanej wiadomości, które zbierane są przez Administratora za pomocą formularza znajdującego się na jego stronie internetowej, do której link umieszczony został pod adresem polskiepaulownie.pl. Celem zbierania powyższych danych jest:
                        </ArticleText>
                        <List>
                            {privacyData.map((data: string, index: number) => (
                                <ListItem key={index}>{data}</ListItem>
                            ))}
                        </List>

                        <ArticleText>
                            Powyższe dane osobowe przetwarzane są przez Administratora jedynie na podstawie zgody udzielonej przez osoby Odwiedzające stronę.                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='period'>
                        <ArticleTitle>
                            Okres przechowywania danych
                        </ArticleTitle>
                        <ArticleText>
                            Dane osobowe będą przetwarzane przez czas świadczenia usług, a dalej jedynie, jeżeli jest to dozwolone lub wymagane przez przepisy prawa, np. w celach statystycznych, rozliczeniowych lub dochodzenia roszczeń. Wówczas dane osobowe będą przetwarzane przez okres niezbędny do realizacji tych celów.                         </ArticleText>

                        <ArticleText>
                            Dane osobowe przetwarzane w celu przesyłania informacji handlowej będą przetwarzane do czasu wycofania zgody na ich przetwarzanie (zgodę można wycofać w każdym czasie).
                        </ArticleText>

                        <ArticleText>
                            Dane osobowe przetwarzane do celów marketingu bezpośredniego (w tym profilowania) będą przetwarzane do czasu złożenia sprzeciwu.
                        </ArticleText>
                        <ArticleText>
                            Dane zbierane za pośrednictwem formularza zbierane są uwzględniając wynikającą z przepisów prawa zasadę minimalizacji danych tj. są adekwatne, stosowne oraz ograniczone do tego, co niezbędne dla celów, w których są przetwarzane.
                        </ArticleText>

                    </ArticleContent>


                    <ArticleContent id='rights'>
                        <ArticleTitle>
                            Uprawnienia Odwiedzających stronę
                        </ArticleTitle>
                        <ArticleText>
                            W związku z przetwarzaniem danych osobowych, Odwiedzającym stronę przysługują związane z tym uprawnienia:
                        </ArticleText>
                        <List>
                            {rights.map((right: string, index: number) => (
                                <ListItem key={index}>{right}</ListItem>
                            ))}
                        </List>

                        <ArticleText>
                            W przypadku woli skorzystania z wymienionych uprawnień, prosimy o kontakt zgodnie z instrukcją zamieszczoną na końcu niniejszej Polityki.
                        </ArticleText>

                    </ArticleContent>

                    <ArticleContent id='recipients'>
                        <ArticleTitle>Odbiorcy danych</ArticleTitle>
                        <ArticleText>Odbiorcami danych osobowych Odwiedzających stronę mogą być inne podmioty przetwarzające dane w imieniu Administratora, w szczególności mogą to być podmioty świadczące na rzecz Administratora usługi np. informatyczne, windykacyjne, prawne, księgowe i inne usługi pomocnicze. Dodatkowo odbiorcami danych mogą być także sądy i organy administracji państwowej oraz instytucje uprawnione do dostępu do danych na podstawie przepisów prawa, takie jak np. Urząd Skarbowy.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='cookies'>
                        <ArticleTitle>
                            Czy używamy plików cookie?
                        </ArticleTitle>

                        <ArticleText>
                            W trakcie świadczenia usług, stosujemy profesjonalne technologie do gromadzenia i zapisywania informacji, takie jak pliki cookies. Są to powszechnie stosowane, niewielkie pliki zawierające ciąg znaków, które są wysyłane i zapisywane na używanym przez Odwiedzjącego stronę urządzeniu końcowym (np. komputerze, laptopie, tablecie, smartfonie) podczas odwiedzania przez niego strony internetowej. Informacje te przesyłane są do schowka używanej przeglądarki, która wysyła je z powrotem przy następnych wejściach na stronę internetową. Pliki cookies zawierają informacje niezbędne do prawidłowego korzystania ze strony internetowej. Najczęściej zawierają one nazwę strony internetowej z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Dostęp do danych z plików cookies mogą posiadać również podmioty zewnętrzne, wymienione w niniejszej Polityce.
                        </ArticleText>

                        <ArticleText>
                            Pliki cookies przetwarzamy na podstawie art. 173 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz. U. z 2016 r., Nr 0, poz. 1489 z późn. zm.).
                        </ArticleText>

                        <ArticleText>
                            Na podstawie zebranych plików cookies nie można w żaden sposób zidentyfikować osoby Odwiedzającej stronę internetową, nie można też uzyskać żadnych innych informacji od Odwiedzającego stronę.
                        </ArticleText>

                        <ArticleText>
                            Pliki cookies Administratora pozwalają na rozpoznanie urządzenia Odwiedzającego stronę i wyświetlenie strony internetowej Administratora dostosowanej do jego indywidualnych oczekiwań, przez co korzystanie z jego funkcjonalności staje się łatwiejsze i przyjemniejsze. Dzięki zapisaniu tych plików na urządzeniu Odwiedzającego stronę, możliwe jest np. prowadzenie statystyki przez Administratora czy dostosowanie strony do preferencji Odwiedzającego stronę, jak np. rozmieszczenie treści, język czy jej kolor.
                        </ArticleText>

                        <ArticleText>
                            Administrator może wykorzystywać pliki cookies stosowane przez Google Inc. 1600 Amphitheatre Pkwy, Mountain View, CA 94043, Stany Zjednoczone w ramach usług w szczególności:
                        </ArticleText>

                        <List>
                            {coockies.map((cookie: string, index: number) => (
                                <ListItem key={index}>{cookie}</ListItem>
                            ))}
                        </List>

                        <ArticleText>
                            Korzystanie z plików cookies podmiotów trzecich jest uwarunkowane polityką prywatności i plików cookies, stosowaną przez te podmioty.
                        </ArticleText>

                        <ArticleText>
                            Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www. W celu zarządzania ustawieniami cookies wybierz z listy poniżej przeglądarkę internetową/ system i postępuj zgodnie z instrukcjami:
                        </ArticleText>

                        <List>
                            {browsers.map((browser: string, index: number) => (
                                <ListItem key={index}>{browser}</ListItem>
                            ))}
                        </List>
                    </ArticleContent>

                    <ArticleContent id='extinsions'>
                        <ArticleTitle>
                            Zastosowanie wtyczek mediów społecznościowych
                        </ArticleTitle>

                        <ArticleText>
                            Wtyczki społecznościowe to przyciski dostępne na naszej stronie internetowej. Bez Państwa ingerencji przyciski te podczas odwiedzin na naszej stronie internetowej nie wysyłają żadnych danych do poszczególnych serwisów społecznościowych. Dopiero po kliknięciu danego przycisku nawiązywane jest bezpośrednie połączenie z serwerem danego serwisu społecznościowego i może on wówczas gromadzić dane i korzystać z plików cookie. Więcej informacji na temat plików cookie można znaleźć w naszych wskazówkach dot. plików cookie.
                        </ArticleText>

                        <ArticleText>
                            Jeśli są Państwo zalogowani do serwisu społecznościowego, może on przypisać Państwa wizytę na tej stronie internetowej do Państwa konta użytkownika. Serwis społecznościowy nie może przyporządkować odwiedzin na innych stronach internetowych RONAL, dopóki nie zostanie również na nich kliknięty odpowiedni przycisk.
                        </ArticleText>
                        <ArticleText>
                            Nie mamy wpływu na zakres danych, jakie są pobierane przez serwisy społecznościowe po kliknięciu odpowiedniego przycisku. Informacje o celu gromadzenia danych, zakresie gromadzonych danych, ich dalszym przetwarzaniu i wykorzystywaniu przez poszczególne serwisy społecznościowe oraz związanych z tym prawach użytkowników i możliwych ustawieniach w celu ochrony prywatności podane są w politykach prywatności poszczególnych serwisów społecznościowych.
                        </ArticleText>

                        <ArticleText>
                            Korzystamy z wtyczek społecznościowych następujących serwisów:
                        </ArticleText>

                        <List>
                            {extinsions.map((extinsion: string, index: number) => (
                                <ListItem>{extinsion}</ListItem>
                            ))}
                        </List>

                        <ArticleText>
                            Jeśli posiadają Państwo profil w którymś serwisie społecznościowym, ale nie chcą, aby operator serwisu za pośrednictwem naszej strony gromadził dane dotyczące Państwa i ew. łączył je z innymi zapisanymi danymi, przed odwiedzeniem innych stron internetowych prosimy o całkowite wylogowanie się z danego serwisu społecznościowego i ewentualnie usunięcie odpowiednich plików cookie.
                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='changes'>
                        <ArticleTitle>Zmiany Polityki prywatności</ArticleTitle>
                        <ArticleText>
                            Zastrzegamy sobie prawo do zmiany niniejszej Polityki prywatności. W takim przypadku, opublikujemy te zmiany na tej stronie tydzień przed wprowadzeniem zmian.
                        </ArticleText>
                    </ArticleContent>

                    <ArticleContent id='contact'>
                        <ArticleTitle>Kontakt i zgłoszenia</ArticleTitle>
                        <ArticleText>
                            Nieustannie czynimy wszystko, by przetwarzać dane osobowe Odwiedzających stronę z dochowaniem najwyższych standardów. Dlatego posiadamy wdrożony system niezwłocznej reakcji na sytuacje zagrożeń tych standardów. W przypadku zidentyfikowania ich zagrożenia lub naruszenia, prosimy o niezwłoczny kontakt z nami.                        </ArticleText>
                        <ArticleText>
                            Z chęcią zapoznamy się ze wszelkimi informacjami zwrotnymi. Dlatego w przypadku jakichkolwiek pytań, żądań lub wątpliwości związanych z przetwarzaniem danych osobowych, zachęcamy do kontaktu.
                        </ArticleText>
                    </ArticleContent>


                </Article>
            </ContentWrapper>
        </Wrapper>
    );
};

