import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Navbar } from "../common/Navbar/navbar";
import { Footer } from "../common/Footer/footer";
import { Home } from "../features/Home/home";
import { About } from "../features/About/about";
import { Contact } from "../features/Contact/contact";
import { Gallery } from "../features/Gallery/gallery";
import { Cultivation } from "../features/Cultivation/cultivation";
import { Offer } from "../features/Offer/offer";
import { Calculator } from "../features/Offer/Calculator/calculator";
import axios from 'axios';
import { useEffect } from "react";
import { DropBox } from "../common/DropBox/dropBox";
import { ToastContainer } from "react-toastify";
import { Privacy } from "../common/Footer/Privacy/privacy";
import { Blog } from "../features/Blog/blog";
import { Article } from "../features/Blog/Article/article";
import { Form } from "../features/Form/form";
import { Faq } from "../features/FAQ/faq";
import { AddPost } from "../features/AddPost/addPost";
import { Contest } from "../features/Contest/contest";
import { Helmet } from "react-helmet";
import { Plantation } from "../features/Plantation/plantation";

function App() {

  useEffect(() => {
    const sendConversionEvent = async () => {
      const accessToken = 'EAANOtJudY6UBOZCr1DKE2csbA1Df4NFHiQXO4smh5iNgAvHSfmXP5BGGNsej64avZBk4YRxoQPmMJFV1GB06A8ZAZBWhZBTZAqiMKwm7q6wMSEZCII5BxnIUFLic7X8qigRR2wZAKNKZBU2lv5KCWPtlvxWMvUqWmJJqAjlVCIOqZCeWllZCxelkLRjGJiMl0NzWG4FTwZDZD';
      const endpoint = 'https://graph.facebook.com/v12.0/2803638283116524/conversions';

      try {
        const response = await axios.post(endpoint, {
          access_token: accessToken,
          event_name: 'PageView',
        });

        console.log(response.data);
      } catch (error) {
        console.error('Błąd podczas wysyłania zdarzenia konwersji:', error);
      }
    };

    sendConversionEvent();
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <title>Paulownie - paulownia Shang Tong sadzonki</title>
        <meta name="description" content="Paulownia Shang Tong - sadzonki idealne do szybkiego wzrostu, odporne na warunki atmosferyczne. Kup teraz i wspieraj polską produkcję drzew." />
        <meta name="keywords" content="paulownia, drzewa tlenowe, sadzonki paulowni, uprawa paulowni" />
      </Helmet>
      <Navbar />
      <DropBox />
      <Routes>
        <Route path="/Drzewa-tlenowe" element={
          <>
            <Helmet>
              <title>Strona Główna - Polskie Paulownie</title>
              <meta name="description" content="Witamy na stronie głównej Polskie Paulownie. Dowiedz się więcej o uprawie i pielęgnacji paulowni oraz naszej ofercie sadzonek." />
              <meta name="keywords" content="paulownia, drzewa tlenowe, sadzonki paulowni, uprawa paulowni" />
            </Helmet>
            <Home />
          </>
        } />
        <Route path={"*"} element={<Navigate replace to="/Drzewa-tlenowe" />} />
        <Route path="/O-firmie-Polskie-Paulownie" element={
          <>
            <Helmet>
              <title>Dowiedz się więcej o firmie Polskie Paulownie</title>
              <meta name="description" content="Poznaj naszą firmę Polskie Paulownie i naszą misję wspierania polskiej produkcji drzew tlenowych." />
              <meta name="keywords" content="paulownia, o nas, firma, drzewa tlenowe" />
            </Helmet>
            <About />
          </>
        } />
        <Route path="Nasza-Oferta-Paulownie" element={
          <>
            <Helmet>
              <title>Nasza oferta - Sadzonki paulowni</title>
              <meta name="description" content="Sprawdź naszą ofertę sadzonek paulowni. Szybkorosnące drzewa idealne dla polskich upraw." />
              <meta name="keywords" content="paulownia, oferta, sadzonki paulowni, drzewa tlenowe" />
            </Helmet>
            <Offer />
          </>
        } />
        <Route path="/Polskie-Paulownie-Kontakt" element={
          <>
            <Helmet>
              <title>Kontakt - Polskie Paulownie</title>
              <meta name="description" content="Skontaktuj się z nami, aby dowiedzieć się więcej o naszej ofercie i drzewach paulowni." />
              <meta name="keywords" content="paulownia, kontakt, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Contact />
          </>
        } />
        <Route path="/Galeria" element={
          <>
            <Helmet>
              <title>Zdjęcia sadzonek oraz drzew Paulowni</title>
              <meta name="description" content="Zobacz galerię zdjęć naszych sadzonek oraz drzew paulowni w różnych etapach wzrostu." />
              <meta name="keywords" content="paulownia, galeria, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Gallery />
          </>
        } />
        <Route path="/Uprawa-paulowni" element={
          <>
            <Helmet>
              <title>Czytaj więcej na temat upraw Paulowni</title>
              <meta name="description" content="Dowiedz się więcej o uprawie paulowni, jak dbać o drzewa tlenowe i jakie korzyści niesie ich hodowla." />
              <meta name="keywords" content="paulownia, uprawa, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Cultivation />
          </>
        } />
        <Route path="/Kalkulator" element={
          <>
            <Helmet>
              <title>Zaplanuj swoją plantację drzew tlenowych</title>
              <meta name="description" content="Skorzystaj z naszego kalkulatora, aby zaplanować swoją plantację drzew tlenowych." />
              <meta name="keywords" content="paulownia, kalkulator, plantacja, drzewa tlenowe" />
            </Helmet>
            <Calculator />
          </>
        } />
        <Route path="/Polityka-prywatności" element={
          <>
            <Helmet>
              <title>Polityka prywatności - Polskie Paulownie</title>
              <meta name="description" content="Zapoznaj się z naszą polityką prywatności i dowiedz się, jak chronimy Twoje dane." />
              <meta name="keywords" content="paulownia, polityka prywatności, dane osobowe, drzewa tlenowe" />
            </Helmet>
            <Privacy />
          </>
        } />
        <Route path="/Blog" element={
          <>
            <Helmet>
              <title>Blog poświęcony drzewom tlenowym (Paulownie, paulownia, sadzonki)</title>
              <meta name="description" content="Czytaj nasze artykuły na temat drzew tlenowych, ich uprawy i korzyści płynących z ich hodowli." />
              <meta name="keywords" content="paulownia, blog, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Blog />
          </>
        } />
        <Route path="/Blog/:id" element={
          <>
            <Helmet>
              <title>Artykuł na blogu - Polskie Paulownie</title>
              <meta name="description" content="Przeczytaj nasz najnowszy artykuł na blogu o drzewach tlenowych i ich uprawie." />
              <meta name="keywords" content="paulownia, blog, artykuł, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Article />
          </>
        } />
        <Route path="/Formularz" element={
          <>
            <Helmet>
              <title>Formularz zamówieniowy</title>
              <meta name="description" content="Wypełnij nasz formularz zamówieniowy, aby zamówić sadzonki paulowni." />
              <meta name="keywords" content="paulownia, formularz, zamówienie, drzewa tlenowe" />
            </Helmet>
            <Form />
          </>
        } />
        <Route path="/Nasza-plantacja" element={
          <>
            <Helmet>
              <title>Nasza Plantacja</title>
              <meta name="description" content="Sekcja poświęcona naszej plantacji" />
              <meta name="keywords" content="paulownia, formularz, zamówienie, drzewa tlenowe" />
            </Helmet>
            <Plantation />
          </>
        } />
        <Route path="/FAQ" element={
          <>
            <Helmet>
              <title>Najczęściej zadawane pytania</title>
              <meta name="description" content="Odpowiedzi na najczęściej zadawane pytania dotyczące uprawy paulowni i naszych sadzonek." />
              <meta name="keywords" content="paulownia, FAQ, pytania, drzewa tlenowe, sadzonki paulowni" />
            </Helmet>
            <Faq />
          </>
        } />
        <Route path="/Regulamin konkursu" element={
          <>
            <Helmet>
              <title>Konkurs Polskie Paulownie</title>
              <meta name="description" content="Weź udział w naszym konkursie i wygraj sadzonki paulowni oraz inne nagrody." />
              <meta name="keywords" content="paulownia, konkurs, nagrody, drzewa tlenowe" />
            </Helmet>
            <Contest />
          </>
        } />
        <Route path="/DodajPostMaciej" element={<AddPost />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
