import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    margin-top: 10%;
    flex-direction: column;
    gap: 20px;
    justify-content: start;
    align-items: center;
    width: 100%;
`;

export const Title = styled.h1`
    font-size: 40px;
    font-weight: normal;
    text-align: center;
    color: ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
      font-size: 24px;
    };
`;
export const Image = styled.img`
    margin: 0 auto ;
    width: 260px;
    height: 260px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        width: 120px;
    };
`;