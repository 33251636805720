import styled, { css } from "styled-components";
import { opacitySlide, slideBottom, slideLeft, slideRight } from "../../core/animations";
import bg from '../../common/Images/BlogImages/bg.jpg';
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1720px;
    margin: 0 auto;
    padding: 10px;
`;

export const Background = styled.img <{ right?: boolean, left?: boolean, bottom?: boolean }>`
    position: absolute;
    top: 40px;
    left: 0px;
    opacity: 0.15;
    max-width: 800px;
    z-index: -1;

    ${({ right }) => right && css`
        max-width: 400px;
        top: 740px;
        right: 0px;
        left: auto;
        opacity: 0.4;
    `};
`;
export const Header = styled.header`
    width: 100%;
    height: 240px;
    background-image: url("${bg}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 160px;
    };
`;

export const HeaderTitle = styled.h1`
    position: absolute;
    font-size: 100px;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    animation: ${slideBottom} 1s;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 80px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 44px;
    };
`;

export const BlogWrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    padding: 40px;
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
      padding: 12px;
    };
`;

export const Title = styled.h2`
    font-size: 48px;
    text-align: center;
    color:${({ theme }) => theme.color.secondColor};
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 28px;
    };
`;

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        grid-template-columns: 3fr 2fr;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
    };
`;

export const Content = styled.div`
    padding: 40px 0px;
    display: grid;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
      padding: 0px;
    };
`;

export const SectionTitle = styled.h3`
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    color: #787878;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
      font-size: 24px;
    };
`;

export const TilesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 52px;
`;

export const Tile = styled(Link) <{ reversed?: boolean }>`
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: start;
    box-shadow: 0 0 20px grey;
    border-radius: 10px;
    align-items: center;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    animation: ${slideLeft} 1s;
    max-width: 1100px;
    background: ${({ theme }) => theme.color.mainColor};

    ${({ reversed }) => reversed && css`
        flex-direction: row-reverse;
        grid-template-columns: 1fr 2fr;
        animation: ${slideRight} 1s;
    `};

    &:hover {
        transform:scale(1.02);
        box-shadow: 0 0 20px ${({ theme }) => theme.color.secondColor} 
    };

    &:hover div {
        opacity: 1;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        flex-wrap:wrap;
        padding:12px;
    };
`;

export const TileImage = styled.img <{ reversed?: boolean }>`
    max-width: 440px;
    display: block;
    width:100%;
    transition: 0.3s;

    ${({ reversed }) => reversed && css`
        border-radius: 0 10px 10px 0;
    `};

`;

export const ReadPost = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: light;
    letter-spacing: 1px;
    background: rgba(15,119,1, 0.7);
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
`;

export const TileImageWrapper = styled.div`
    position: relative;
        &:hover ${ReadPost} {
            opacity: 1;
        }
`;

export const TextWrapper = styled.div`
    padding: 32px;
    display: grid;
    max-width: 60%;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
      padding: 12px;
      max-width: 100%;
    };
`;

export const TileTitle = styled.h4`
    text-align: center;
    font-size: 24px;
    margin: 0;
    color: ${({ theme }) => theme.color.secondColor};
    font-weight: normal;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
      font-size: 20px;
    };
`;

export const TileAuthor = styled.p`
    text-align: end;
    color: #959595;
    margin: 0;
    font-family: open sans;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
      font-size: 12px;
    };
`;

export const TileDescription = styled.span`
    font-size: 20px;
    margin: 0;
    color: ${({ theme }) => theme.color.fontColor};
    text-align: justify;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
      font-size: 16px;
    };
`;

export const Navigation = styled.div`
    display: grid;
    position: sticky;
    z-index: 8; 
    top: 90px;
    gap: 24px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: none;
    };
`;


export const LinksWrapper = styled.div`
    display: grid;
    gap: 20px;
    justify-content: center;
`;

export const NavLink = styled(Link)`
    font-size: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondColor};
    text-align: center;
    transition: 0.3s;

    &:hover {
        opacity: 0.6;
    }
`;