import React from 'react';
import { Wrapper, Header, Background, HeaderTitle, ContentWrapper, Text, Image } from './styledPlantation';
import bg from '../../common/Images/backgroundImages/PP_wektor_01.svg';
import { Plantations } from '../../core/arrays';
import { Gallery } from './Gallery/gallery';
import { VideoSection } from './Video/videoSection';

export const Plantation = () => {
    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Nasza plantacja</HeaderTitle>
            </Header>
            <Background src={bg} alt='background' />
            {Plantations.map((plantation, index) => (
                <ContentWrapper key={index} reverse={index % 2 === 1}>
                    <Text> {plantation.text}</Text>
                    <Image src={plantation.img} alt='background' />
                </ContentWrapper>
            ))}

            <VideoSection />
            <Gallery />
        </Wrapper>
    );
};

