import React from 'react';
import { Background, Content, ContentTitle, CustomSlider, Header, HeaderTitle, ImageSection, Img, LinkButton, LinkContainer, LinkSpan, LinksWrapper, NavLink, Navigation, Paragraph, SectionTitle, TextSection, TextTile, Wrapper } from './styledArticle';
import bg from '../../../common/Images/backgroundImages/PP_wektor_01.svg';
import bg2 from '../../../common/Images/backgroundImages/PP_wektor_05.svg';
import img1 from '../../../common/Images/BlogImages/GALERIA_002.jpg';
import img2 from '../../../common/Images/BlogImages/GALERIA_003.jpg';
import img3 from '../../../common/Images/BlogImages/GALERIA_011.jpg';
import img4 from '../../../common/Images/BlogImages/GALERIA_020.jpg';
import img5 from '../../../common/Images/BlogImages/GALERIA_025.jpg';
import img6 from '../../../common/Images/BlogImages/GALERIA_028.jpg';
import { useParams } from 'react-router-dom';
import { fetchPosts } from '../../../core/fetchPosts';
import { useQuery } from '@tanstack/react-query';
import ReactHtmlParser from 'react-html-parser';
import { Loader } from '../../../common/Loader/loader';
import { Error } from '../../../common/Error/error';


export const Article = () => {

    const { data, isLoading, isError } = useQuery({
        queryKey: ['posts'],
        queryFn: fetchPosts
    });


    const { id } = useParams();
    const article = data?.find(article => article.id === id);


    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false
    };

    const images = [
        { src: img1 },
        { src: img2 },
        { src: img3 },
        { src: img4 },
        { src: img5 },
        { src: img6 },
    ];


    return (
        <Wrapper>
            {isLoading ? <Loader /> : isError ? <Error /> : (
                <>

                    <Header id='/header'>
                        <HeaderTitle>{article.title}</HeaderTitle>
                    </Header>

                    <Background src={bg} alt='background' />
                    <Background right src={bg2} alt='second background' />

                    <Navigation>
                        <SectionTitle>Spis Treści:</SectionTitle>
                        <LinksWrapper>
                            {article.subpoints?.map((text) => (
                                <NavLink
                                    to={text.title}
                                    key={text.title}
                                    offset={-100}
                                    smooth={true}
                                >
                                    {text.title}
                                </NavLink>
                            ))}
                        </LinksWrapper>
                    </Navigation>

                    <Content>
                        <TextSection>
                            {article.subpoints?.map((text, index) => (
                                <TextTile id={text.title} key={index}>
                                    <ContentTitle key={index}>{text.title}</ContentTitle>
                                    <Paragraph>{ReactHtmlParser(text.content)}</Paragraph>
                                </TextTile>
                            ))}
                        </TextSection>

                        <div>
                            <ImageSection>
                                <CustomSlider {...settings}>
                                    {images.map((img) => (
                                        <Img src={img.src} key={img.src} alt='Swiper' />
                                    ))}
                                </CustomSlider>
                                <LinkContainer>
                                    <LinkSpan>Zapraszamy do zapoznania się z naszą ofertą drzew tlenowych</LinkSpan>
                                    <LinkButton to='Nasza-Oferta-Paulownie' onClick={() => window.scrollTo(0, 0)}>Oferta</LinkButton>
                                </LinkContainer>
                            </ImageSection>
                        </div>
                    </Content>
                </>
            )}
        </Wrapper>
    );
};