import styled from "styled-components";
import background from '../../common/Images/Privacy/bg.jpg';
import { Link } from "react-scroll";
import { opacitySlide, slideBottom } from "../../core/animations";
export const Wrapper = styled.section`
    min-height: 100vh;
`;

export const Header = styled.header`
    width: 100%;
    height: 400px;
    background-image: url("${background}");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 11px 13px 28px 0px rgba(0, 0, 0, 0.50);
    animation: ${opacitySlide} 1s;
    text-align: center;

    
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        height: 160px;
    };

`;

export const HeaderTitle = styled.h1`
    position: absolute;
    stroke: 2px red;
    font-size: 100px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    padding: 10px;
    text-align: center;
    color: white;
    animation: ${slideBottom} 1s;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 80px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 28px;
    };
`;

export const Information = styled.p`
    text-align: center;
    font-size: 24px;
    padding: 80px 80px 0;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
        padding: 40px 20px 0;
    };
`;

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 80px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        padding: 40px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
        padding: 20px;
    };
`;

const activeClassName = "active";

export const Navigation = styled.div`
    display: grid;
    position: sticky;
    z-index: 10; 
    top: 140px;
    gap: 24px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        display: none;
    };
`;

export const NavLink = styled(Link)`
    cursor: pointer;
    font-size: 24px;
    text-transform: capitalize;
    color: #161717;
    transition: 0.3s;

    &:hover {
        opacity: 0.6;
    };

    &.${activeClassName} {
        color: ${({ theme }) => theme.color.secondColor};

        &:hover {
            opacity: 1;
        }
    }
`;

export const Article = styled.article`
    display: grid;
    gap: 52px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 50px;
    };
`;

export const ArticleContent = styled.div`
    display: grid;
    gap: 32px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 20px;
    };
`;

export const ArticleTitle = styled.h2`
    font-size: 64px;
    font-weight: normal;
    letter-spacing: 4px;
    color: ${({ theme }) => theme.color.secondColor};
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 28px;
        letter-spacing: 1px;
    };
`;

export const ArticleText = styled.p`
    font-size: 20px;
    letter-spacing: -0.025ch;
    text-align: justify;
    line-height: 120%;
    color: rgb(109, 109, 109);
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const List = styled.ul`
    margin: 0;
    padding-left: 10%;
`;

export const ListItem = styled.li`
    font-size: 20px;
    letter-spacing: -0.025ch;
    color: rgb(109, 109, 109);
    margin-bottom: 10px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    }
`;

