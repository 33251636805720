import React from 'react';
import { Background, Content, Header, HeaderTitle, Title, Wrapper } from './styledForm';
import { Order } from '../../common/Order/order';
import bg from '../../common/Images/backgroundImages/PP_wektor_01.svg';
import bg2 from '../../common/Images/backgroundImages/PP_wektor_05.svg';
export const Form = () => {
    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Formularz zamówieniowy</HeaderTitle>
            </Header>
            <Content>
                <Background src={bg} alt='background' />
                <Background right src={bg2} alt='background' />

                <Title>Wypełnij formularz a my skontaktujemy się z tobą w celu dogadania szczegołów.</Title>
                <Order />

            </Content>

        </Wrapper>
    );
};

