export const theme = {
    color: {
        mainColor: "#EFEFEF;",
       // secondColor: "rgb(49, 103, 48)",
        secondColor: "rgb(29, 105, 24)",
        hoverSecondColor: 'rgba(15,119,1, 0.9)',
        fontColor: "#000000",
        thirdColor: '#868686',
    },

    breakPoint: {
        firstBreakPoint: 1380,
        secondBreakPoint: 1200,
        mobileMax: 767,
    }
};