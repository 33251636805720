import React, { useState } from 'react';
import { Wrapper, Form, Label, Input, Button } from './styledPin';

interface PinVerificationProps {
    onVerify: (isVerified: boolean) => void;
}

const PinVerification: React.FC<PinVerificationProps> = ({ onVerify }) => {
    const [pin, setPin] = useState('');
    const correctPin = '6969';


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (pin === correctPin) {
            onVerify(true);
        } else {
            alert('Incorrect PIN. Please try again.');
        }
    };

    return (
        <Wrapper>
            <Form onSubmit={handleSubmit}>
                <Label>
                    <Input
                        type="password"
                        placeholder="Wprowadź PIN"
                        value={pin}
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPin(event.target.value)}
                    />
                </Label>
                <Button type="submit">Zweryfikuj PIN</Button>
            </Form>
        </Wrapper>
    );
};

export default PinVerification;
