import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.section`
    margin-bottom: 32px;
    max-height: 540px;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content:center;
`;

export const Video = styled.video`
    width: 100%;
    object-fit: contain;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        width: 140%;
    };
`;

export const TextWrapper = styled.div`
    display: grid;
    text-align: center;
    background: rgba(255,255,255, 0.6);
    padding: 64px;
    position: absolute;
    border-radius: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        padding: 10px 32px;
        border-radius: 10px;
    };
`;

export const Logo = styled.h3`
    color: ${({ theme }) => theme.color.secondColor};
    font-size: 80px;
    font-weight: 400;
    letter-spacing: 8.37px;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 28px;
        letter-spacing: normal;
    };
`;

export const LogoSpan = styled.span`
    color: #676360;
`;

export const Caption = styled.h4`
    color: #475228;
    text-align: center;
    font-size: 54px;
    font-weight: 400;
    letter-spacing: 2.7px;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 20px;
        letter-spacing: 1px;
    };
`;

export const ButtonLink = styled(Link)`
    padding: 14px 40px;
    margin: 20px auto 0;
    color: #FFF;
    font-size: 20px;
    text-decoration: none;
    border-radius: 10px;
    background: ${({ theme }) => theme.color.secondColor};
    transition: 0.3s;
    white-space: nowrap;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
        padding: 10px 20px;
        margin: 10px auto 0;
    };

    &:hover{
         opacity: 0.8;
    };
`;