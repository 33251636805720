import React from 'react';
import { Wrapper, Video } from './styledVideoSection';
import bgVideo from '../../../common/videos/bg.mp4';

export const VideoSection = () => {
    return (
        <Wrapper>
            <Video src={bgVideo} autoPlay loop muted playsInline={true} />
        </Wrapper>
    );
};