import React from 'react';
import { Wrapper, Header, HeaderTitle, ContentWrapper, Article, ArticleContent, ArticleTitle, ArticleText, Navigation, NavLink } from './styledContest';
import { ContestLinks } from '../../core/arrays';

export const Contest = () => {
    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Regulamin konkursu <p style={{ margin: 0 }}>"Pokaż nam swój ogródek"</p></HeaderTitle>
            </Header>
            <ContentWrapper>

                <div>
                    <Navigation>
                        {ContestLinks.map((link, index) => (
                            <NavLink
                                key={index}
                                offset={-150}
                                spy={true}
                                to={link.to}
                                smooth={true}
                            >
                                {link.text}
                            </NavLink>
                        ))}
                    </Navigation>
                </div>
                <Article>
                    <ArticleContent id='1'>
                        <ArticleTitle>Postanowienia ogólne</ArticleTitle>
                        <ArticleText>1.	Organizatorem konkursu jest firma Polskie Paulownie z siedzibą w Ul. Kolejowa 105, 43-178 Ornontowice, NIP: 6350011989.</ArticleText>
                        <ArticleText>2.	Fundatorem nagrody jest Organizator.</ArticleText>
                        <ArticleText>3.	Konkurs nie jest stworzony, administrowany ani sponsorowany przez Facebook. Serwis facebook.com nie ponosi żadnej odpowiedzialności za jakiekolwiek działania związane z organizacją konkursu na łamach serwisu.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='2'>
                        <ArticleTitle>Warunki uczestnictwa</ArticleTitle>
                        <ArticleText>4.	W konkursie mogą wziąć udział wyłącznie osoby pełnoletnie zamieszkałe na terenie Polski.</ArticleText>
                        <ArticleText>5.	Warunkiem udziału w konkursie jest posiadanie zweryfikowanego konta w serwisie Facebook.</ArticleText>
                        <ArticleText>6.	Konkurs trwa od 5 czerwca 2024 do 20 czerwca 2024, do końca dnia.</ArticleText>
                        <ArticleText>7.	Wyniki Konkursu zostaną ogłoszone 25 czerwca 2024 za pośrednictwem strony na Facebooku Polskie Paulownie.</ArticleText>
                        <ArticleText>8.	Konkurs odbywa się za pośrednictwem fanpage na portalu społecznościowym Facebook znajdującym się pod adresem: <a style={{ color: '#0F7701' }} href='https://www.facebook.com/polskiepaulownie'>https://www.facebook.com/polskiepaulownie</a> </ArticleText>
                        <ArticleText>9.	Będzie nam niezmiernie miło, jeśli uczestnik polubi/zaobserwuje stronę Polskie Paulownie.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='3'>
                        <ArticleTitle>Zadanie konkursowe</ArticleTitle>
                        <ArticleText>10.    Zadanie konkursowe polega na przesłaniu zdjęcia swojego ogródka oraz miejsca, w którym widział(a)byś swoje drzewko paulowni, oraz napisaniu w 2-3 zdaniach, jak drzewko ubogaci Twoje zielone miejsce.</ArticleText>
                        <ArticleText>11.	Każdy uczestnik może zgłosić tylko jedną propozycję odpowiedzi.</ArticleText>
                        <ArticleText>12.	W konkursie zostanie wyłonionych 3 zwycięzców.</ArticleText>
                        <ArticleText>13.	Najciekawsza, najlepsza, najzabawniejsza praca konkursowa zostanie wybrana przez komisję konkursową składającą się z pracowników firmy Polskie Paulownie.</ArticleText>
                        <ArticleText>14.	Zwycięzcy Konkursu zostaną powiadomieni o wygranej i warunkach odbioru Nagrody za pośrednictwem wiadomości prywatnej wysłanej na portalu Facebook.</ArticleText>
                        <ArticleText>15.	Organizator zwraca uwagę na poszanowanie twórczości innych i zachowanie kultury w wypowiedziach.</ArticleText>
                        <ArticleText>16.	Zgłoszenia zawierające wulgaryzmy i niecenzuralne słowa będą usuwane i nie będą brały udziału w konkursie.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='4'>
                        <ArticleTitle>Nagroda</ArticleTitle>
                        <ArticleText>17.	Nagrodą w konkursie są sadzonki paulowni (12 sztuk).</ArticleText>
                        <ArticleText>18.	Nagrodę można odebrać osobiście w siedzibie firmy lub zostanie wysłana na wskazany adres.</ArticleText>
                        <ArticleText>19.	Nagrodę należy odebrać najpóźniej do dnia 31 lipca 2024, po tym czasie Nagroda traci ważność.</ArticleText>
                        <ArticleText>20.	Organizator ma prawo podać dane Zwycięzcy na stronie na Facebooku.</ArticleText>
                        <ArticleText>21.	Organizator nie ponosi odpowiedzialności za brak możliwości przekazania nagrody z przyczyn leżących po stronie Uczestnika. W takim wypadku nagroda przepada.</ArticleText>
                        <ArticleText>22.	Organizator nie ponosi odpowiedzialności za nieprawidłowe dane wskazane przez Uczestnika, a w szczególności za zmianę danych osobowych uniemożliwiających odszukanie Uczestnika i poinformowanie o przyznaniu Nagrody.</ArticleText>
                        <ArticleText>23.	W przypadku wykrycia działań niezgodnych z Regulaminem, próby wpływania na wyłonienie Zwycięzcy w sposób niedozwolony, szczególnie poprzez zakładanie fikcyjnych profili w serwisie Facebook, dany uczestnik może zostać wykluczony z Konkursu.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='5'>
                        <ArticleTitle>Reklamacje</ArticleTitle>
                        <ArticleText>24.	Wszelkie reklamacje oraz uwagi dotyczące zasad, przeprowadzenia lub rozstrzygnięcia konkursu prosimy zgłaszać mailowo na adres: polskie.paulownie@gmail.com.</ArticleText>
                        <ArticleText>25.	Reklamacja powinna zawierać imię i nazwisko Uczestnika oraz uzasadnienie reklamacji. W tytule wiadomości prosimy dodać opis "Konkurs na Facebooku - Pokaż nam swój ogródek".</ArticleText>
                        <ArticleText>26.	Reklamacje rozpatrywane są w terminie 30 dni od daty ich wpłynięcia.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='6'>
                        <ArticleTitle>Obowiązek informacyjny</ArticleTitle>
                        <ArticleText>27.	Informujemy, że administratorem danych osobowych uczestników Konkursu jest Polskie Paulownie z siedzibą Ul. Kolejowa 105, 43-178 Ornontowice. W sprawach dotyczących przetwarzania danych osobowych należy kontaktować się z Inspektorem Ochrony Danych pod adresem e-mail: polskie.paulownie@gmail.com.</ArticleText>
                        <ArticleText>28.	Dane osobowe będą przetwarzane w celu i zakresie niezbędnym do przeprowadzenia Konkursu, na podstawie art. 6 ust. 1. lit. b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 UE (dalej: „RODO”). Dane zostały zebrane przez fanpage na Facebooku Polskie Paulownie.</ArticleText>
                        <ArticleText>29.	Dane osobowe będą przetwarzane przez okres niezbędny do realizacji Konkursu, w tym wydania Nagrody i ogłoszenia informacji o zwycięzcy (oraz innych nagrodzonych osobach), a także przechowywane do momentu przedawnienia ewentualnych roszczeń lub wygaśnięcia obowiązku archiwizacji danych wynikającego z przepisów prawa.</ArticleText>
                    </ArticleContent>

                    <ArticleContent id='7'>
                        <ArticleTitle>Postanowienia końcowe</ArticleTitle>
                        <ArticleText>30.	W kwestiach nieuregulowanych niniejszym Regulaminem stosuje się przepisy Kodeksu cywilnego i inne przepisy prawa.</ArticleText>
                        <ArticleText>31.	Spory odnoszące się i wynikające z Konkursu będą rozwiązywane przez sąd powszechny właściwy miejscowo dla siedziby organizatora.</ArticleText>
                        <ArticleText>32.	Organizator zastrzega sobie prawo do zmiany zasad Konkursu w trakcie jego trwania. Informacja o zmianach będzie zamieszczona na fanpage Polskie Paulownie.</ArticleText>
                        <ArticleText>33.	Biorąc udział w konkursie, Użytkownik zgadza się z postanowieniami niniejszego Regulaminu.</ArticleText>
                    </ArticleContent>
                </Article>
            </ContentWrapper>
        </Wrapper>
    );
};

