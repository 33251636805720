import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const Wrapper = styled.section`
    display: grid;
    align-items: center;
    max-width: 1320px;
    padding: 40px 0px;
    margin: 0 auto;
    gap: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 12px;
        gap: 20px;
    };
`;

export const ImagesWrapper = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        grid-template-columns: 1fr;
        margin: 0 auto;
    };
`;

export const Title = styled.h2`
    text-align: center;
    font-size: 48px;
    margin: 20px 0 10px 0;
    color: ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 28px;
    };
`;

export const GalleryImage = styled.img`
    width: 100%;
    transition: 0.3s linear;
    &:hover {
        transform: scale(1.04);
        cursor: pointer;
        opacity: 70%;
    }
`;


export const Background = styled.img <{ right?: boolean, }>`
    position: absolute;
    top: 4140px;
    left: 0px;
    opacity: 0.4;
    max-width: 600px;
    z-index: -1;
    transform: rotate(-0.25turn);
`;


export const Pics = styled.div`
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 8px -8px 10px ${({ theme }) => theme.color.thirdColor};
`;

export const FullScreenWrapper = styled.div`
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    padding: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        padding: 12px;
    };
`;

export const CustomSwiper = styled(Swiper)`
    width: 100%;
    height: 100%;

    .swiper-button-prev {
        color: ${({ theme }) => theme.color.secondColor};
    };

    .swiper-button-next {
        color: ${({ theme }) => theme.color.secondColor};
    };
`;

export const CustomSlide = styled(SwiperSlide)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
`;

export const FullScreenImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    user-select: none;
`;

export const CloseButton = styled.button`
    position: fixed;
    z-index: 1;
    top: 20px;
    color: ${({ theme }) => theme.color.mainColor};
    transition: 0.3s;
    cursor: pointer;
    right: 80px;
    background: transparent;
    border: none;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        right: 10px;
    };

    &:hover {
        transform: scale(1.2);

        @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
            transform: none;
        };
    }
`;