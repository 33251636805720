import React, { useRef } from 'react';
import { Background, Button, ContactWrapper, Form, Input, InputField, InputWrapper, Inputs, Label, Link, LinkContainer, LinksTitle, LinksWrapper, SVGIcon, Span, TextArea, TextField, Title, Wrapper } from './styledContact';
import facebook from '../../common/Images/SVG/facebook.svg';
import mail from '../../common/Images/SVG/mail.svg';
import phone from '../../common/Images/SVG/phone.svg';
import adress from '../../common/Images/SVG/location.svg';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import olx from '../../common/Images/SVG/olx.svg';
import bg1 from '../../common/Images/backgroundImages/PP_wektor_03.svg';
import bg4 from '../../common/Images/backgroundImages/PP_wektor_06.svg';

export const Contact = () => {

    const notifySuccess = () => toast.success("Twoja wiadomość została wysłana!");
    const notifyError = () => toast.error("Ups. Coś poszło nie tak spróbuj ponownie!");


    const handleSendEmailSuccess = () => {
        notifySuccess();
    };

    const handleSendEmailError = () => {
        notifyError();
    };

    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formElement = event.target as HTMLFormElement;

        emailjs.sendForm('service_154k192', 'template_kxd3htt', formElement, 'xFXcbRydqKtqjDlHZ')
            .then((result) => {
                if (result.text === "OK") {
                    formElement.reset();
                    handleSendEmailSuccess();
                }
            })
            .catch(() => {
                handleSendEmailError();
            });
    };

    console.log();


    return (
        <Wrapper>
            <Title>Masz pytanie?</Title>
            <Span>Skontaktuj się z nami wypełniając formularz lub klikając w link</Span>
            <Background src={bg1} alt='background' />
            <Background src={bg4} bottom alt='background' />
            <ContactWrapper>
                <div>
                    <Form
                        onSubmit={sendEmail}
                        ref={form}
                    >
                        <InputWrapper>
                            <Inputs>
                                <InputField>
                                    <Label>Imię i Nazwisko *</Label>
                                    <Input type='name' name="name" required />
                                </InputField>

                                <InputField>
                                    <Label>E-Mail *</Label>
                                    <Input type='email' name="email" required />
                                </InputField>
                            </Inputs>

                            <InputField>
                                <Label>Temat</Label>
                                <Input type='name' name="topic" />
                            </InputField>
                            <InputField>
                                <Label>Wiadomość *</Label>
                                <TextArea name='message' required ></TextArea>
                            </InputField>
                        </InputWrapper>



                        <Button>Wyślij</Button>
                    </Form>
                </div>


                <div>
                    <LinkContainer>
                        <LinksTitle>Znajdziesz nas również</LinksTitle>
                        <LinksWrapper>
                            <TextField>
                                <SVGIcon src={phone} alt='phone' />
                                <Link href="tel:603554885"> 603 554 885</Link>
                            </TextField>
                            <TextField>
                                <SVGIcon src={facebook} alt='facebook' />
                                <Link href='https://www.facebook.com/profile.php?id=100067548942538' target='blank'>Polskie Paulownie</Link>
                            </TextField>
                            <TextField>
                                <SVGIcon src={mail} alt='e-mail' />
                                <Link href="mailto: polskie.paulownie@gmail.com" >polskie.paulownie@gmail.com</Link>
                            </TextField>
                            <TextField>
                                <SVGIcon src={adress} alt='adress' />
                                <Link target='blank' href='https://www.google.com/maps/place/Kolejowa+105,+43-178+Ornontowice,+Polska/@50.190847,18.762246,13z/data=!4m6!3m5!1s0x4711351f2697d1eb:0x28170e0e39f5a940!8m2!3d50.1908466!4d18.7622456!16s%2Fg%2F11c1z68rz6?hl=pl-PL&entry=ttu'>Ul. Kolejowa 105 Ornontowice</Link>
                            </TextField>
                            <TextField>
                                <SVGIcon src={olx} alt='olx' />
                                <Link href="https://www.olx.pl/oferty/uzytkownik/1jaIGZ/" target='_blank' >Polskie Paulownie</Link>
                            </TextField>
                        </LinksWrapper>
                    </LinkContainer>
                </div>

            </ContactWrapper>
        </Wrapper>
    );
};

