import { getDocs, collection } from "firebase/firestore";
import { db } from './firebaseConfig';

export const fetchPosts = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "posts"));
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        const customError = new Error('There was an error fetching the posts');
        (customError as any).originalError = error;
        throw customError;
    }
};