import planting from '../common/Images/CultivationImages/planting.png'
import desks from '../common/Images/CultivationImages/desks.png'
import pelets from '../common/Images/CultivationImages/pelets.png'
import gardening from '../common/Images/CultivationImages/gardening.png'
import science from '../common/Images/CultivationImages/science.png'
import town from '../common/Images/CultivationImages/town.png'
import city from '../common/Images/CultivationImages/city.png'
import bee from '../common/Images/CultivationImages/bee.png'
import img1 from '../common/Images/Plantation/img1.jpg';
import img2 from '../common/Images/Plantation/img2.jpg';
import img3 from '../common/Images/Plantation/img3.jpg';
import img4 from '../common/Images/Plantation/img4.jpg';
import img5 from '../common/Images/Plantation/img5.jpg';
import img6 from '../common/Images/Plantation/img6.jpg';


export const links = [
    { text: 'Strona Główna', link: '/Drzewa-tlenowe' },
    { text: 'Oferta', link: 'Nasza-Oferta-Paulownie' },
    { text: 'O nas', link: '/O-firmie-Polskie-Paulownie' },
    { text: 'Uprawa paulowni', link: '/Uprawa-paulowni' },
    { text: 'Galeria', link: '/Galeria' },
    { text: 'FAQ', link: '/FAQ' },
    // { text: 'Regulamin konkursu', link: '/Regulamin konkursu' },
    { text: 'Nasza plantacja', link: '/Nasza-plantacja' },
    { text: 'Formularz', link: '/Formularz' },
    { text: 'Kontakt', link: '/Polskie-Paulownie-Kontakt' },
    { text: 'Blog', link: '/Blog' },
];

export const cultivationData = [
    {
        id: 1,
        title: 'Ogrodnictwo',
        text: 'Kiedy paulownia kwitnie to u wszystkich wzbudza zachwyt, można patrzeć i patrzeć oraz czuć prawdziwy powiew wiosny. Wśród kwiatów uwijają się pszczoły, gwarno dyskutując o wiośnie, ale kiedy kwiaty opadną, paulownia zamienia się w zieloną kopułę pięknych liści. Kształt jej kulisty pasuje do naszych polskich ogrodów. Od wielu już lat spotykamy ją pojedynczych nasyceniach, ale także w alejach przy uzdrowiskach, miastach czy też szpitalach. Drzewo można formować, podnosić koronę lub wysmuklać dostosowując do miejsca, w którym przyszło jej rosnąć. Paulownia ma duże liście i kiedy przychodzi pierwszy przymrozek, wówczas liście czernieją w jedną noc i spadają. Z racji swej ciężkości nie fruwają do sąsiadów, ale pod swym konarem rozkruszają się do marca, użyźniając tym samym glebę.',
        image: gardening
    },
    {
        id: 2,
        title: 'Plantacje na drewno użytkowe',
        text: 'Paulownie to szybko rosnące drzewa, które są coraz częściej sadzone ze względu na ich drewno użytkowe. Drewno paulowni jest lekkie i trwałe, co czyni je atrakcyjnym surowcem do produkcji mebli, podłóg i innych wyrobów stolarskich. Ponadto, paulownie są również cenne ze względu na swoje właściwości ekologiczne, ponieważ są efektywne w pochłanianiu dwutlenku węgla z atmosfery. To sprawia, że są one interesującym wyborem dla osób i firm zainteresowanych zrównoważonym gospodarowaniem lasami i produkcją drewna.',
        image: desks
    },
    {
        id: 3,
        title: 'Produkcja Biomasy',
        text: 'Paulownia jest jednym z najefektywniejszych gatunków drzew szybko rosnących, co sprawia, że jest idealna do uprawy w celu produkcji biomasy. Jej drewno jest wyjątkowo lekkie i ma stosunkowo wysoką wartość energetyczną. Drzewa Paulowni mogą być hodowane na plantacjach, a następnie przetwarzane na pellet, brykiety lub drewno opałowe, co przyczynia się do produkcji odnawialnej energii oraz biomasy wykorzystywanej w przemyśle. To zrównoważone źródło biopaliwa, które ma potencjał do zaspokajania rosnącego zapotrzebowania na energię.',
        image: pelets
    },
    {
        id: 5,
        title: 'Rekultywacja Terenów',
        text: 'Drzewa Paulowni stanowią doskonałą opcję dla rekultywacji terenów, które uległy degradacji na skutek wydobycia surowców naturalnych lub innych działalności przemysłowych. Ich szybki wzrost i zdolność do dostosowywania się do różnych warunków klimatycznych i glebowych sprawiają, że są idealnym wyborem. Korzenie Paulowni pomagają w stabilizacji gruntów i zapobiegają erozji. W wyniku tego procesu, tereny, które kiedyś były nieużyteczne, stają się przyjazne dla przyrody, co przyczynia się do odbudowy ekosystemów i przywracania naturalnej równowagi.',
        image: planting
    },
    {
        id: 6,
        title: 'Pszczelarstwo',
        text: 'Roślina należy do gatunku roślin C4 – miododajnych. Od końca kwietnia, przez 5 tygodni, kwitnie w koszykach kwiatowych o wysokości do 60 cm. Z końcem maja kwiaty schną i pojawiają się liście. Najbardziej znana jest jako paulownia puszysta, ale można ją spotkać także pod nazwą: cesarska lub omszona. Wieczorem, gdy pszczoły już nie pracują, słychać jeszcze gwar trzmieli, które zbierają spadź. Z racji, że liście są lepkie, nie spotykamy pod tymi drzewami mszyc, komarów, muszek – chyba boją się, że się przykleją. W Moszczanicy obok Żywca wraz z uczniami klasy pszczelarskiej możemy obserwować cały rok jak pszczoły współpracują z paulownią.',
        image: bee
    },
    {
        id: 7,
        title: 'Sadzenie dla Krajobrazu Miejskiego',
        text: 'Paulownia jest doskonałym dodatkiem do miejskich krajobrazów, który przynosi nie tylko estetyczne korzyści, ale także wpływa na jakość powietrza w obszarach miejskich. Jej piękne, fioletowe kwiaty i dekoracyjne liście czynią ją atrakcyjnym drzewem dla miejskich ogrodów i parków. Ponadto, drzewa te przyczyniają się do oczyszczania powietrza z zanieczyszczeń i dostarczają cennego cienia w cieplejszych miesiącach.',
        image: city
    },
    {
        id: 8,
        title: 'Hodowla Zwierząt',
        text: 'Drzewa Paulowni oferują cenny cień i schronienie dla zwierząt hodowlanych w okresach gorących letnich miesięcy. To gatunek drzewa, który rośnie gęsto i zapewnia przyjemne warunki do wypasu i wypasu zwierząt. Dla rolników, którzy dbają o swoje stada, Paulownia stanowi naturalny sposób na utrzymanie zwierząt w komfortowych warunkach i poprawienie ich samopoczucia.',
        image: town
    },
    {
        id: 9,
        title: 'Badania Naukowe',
        text: 'Paulownia jest przedmiotem licznych badań naukowych związanym z jej uprawą, właściwościami drewna i wpływem na środowisko. Badacze interesują się szczególnie potencjałem tych drzew do sekwestroacji dwutlenku węgla, produkcji biomasy, oraz jako źródła biomasy dla celów energetycznych i przemysłowych. Dzięki swojemu szybkiemu wzrostowi i wszechstronności, Paulownia przyciąga uwagę naukowców dążących do zrównoważonego rozwoju.',
        image: science
    }
];

export const treeHeightPrices = [
    { from: '50 cm', to: '100 cm', price: '70 zł' },
    { from: '100 cm', to: '150 cm', price: '100 zł' },
    { from: '150 cm', to: '200 cm', price: '150 zł' },
    { from: '200 cm', to: '', price: 'cena indywidualna' }
];

export const seedlingQuantityPrices = [
    { from: '1', to: '20', price: '34,90 zł' },
    { from: '21', to: '100', price: '32,50 zł' },
    { from: '101', to: '500', price: '29,90 zł' },
    { from: '501', to: '', price: 'cena indywidualna' }
];

export const declarations = [
    'przetwarzane zgodnie z prawem,',
    'zbierane dla oznaczonych w niniejszym dokumencie i zgodnych z prawem celów,',
    'nie poddawane dalszemu przetwarzaniu niezgodnemu z powyższymi celami,',
    'merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane,',
    'odpowiednio zabezpieczone przed dostępem osób nieuprawnionych, zniszczeniem, ujawnieniem oraz bezprawnym wykorzystaniem,',
    'przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.',
];

export const privacyData = [
    'analiza przesłanego zgłoszenia,',
    'udzielenie odpowiedz na zgłoszenie,',
    'realizacji działań marketingowych, w tym także marketingu bezpośredniego,',
    'wysyłania informacji handlowej drogą elektroniczną, ',
    'realizacji analiz statystycznych, dopasowywania treści Serwisu/oferty/reklam do zainteresowań Użytkownika, monitorowania zainteresowania ofertą grup odbiorców o rożnych cechach. ',
];

export const rights = [
    'prawo dostępu do treści swoich danych,',
    'prawo sprostowania swoich danych,',
    'prawo żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych, ograniczenia przetwarzania lub ich usunięcia, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem przepisów prawa albo są już zbędne do realizacji celu, dla którego zostały zebrane, prawo do przenoszenia danych, ',
    'prawo wniesienia sprzeciwu, ',
    'prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;',
    'prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna, iż przetwarzanie jego danych osobowych narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.;'
];

export const coockies = [
    'Google Adwords – pozwalają one na prowadzenie i ocenę jakości kampanii reklamowych, realizowanych przy użyciu usługi Google Adwords,',
    'Google Analytics – pozwalają one na ocenę jakości kampanii reklamowych, realizowanych przy użyciu usługi Google Adwords, a także na badanie zachowań i ruchu Klientów oraz sporządzanie statystyk ruchu.',
];

export const browsers = [
    'Chrome',
    'Safari',
    'Firefox',
    'Opera',
    'Android',
    'Safari (iOS)',
    'Windows Phone',
    'Blackberry',
];

export const extinsions = [
    'Youtube Inc. (www.youtube.com, YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA);',
    'Facebook Inc. (www.facebook.com, 1601 Willow Road, Menlo Park, CA 94025, USA);',
    'Instagram Inc. (www.instagram.com, 1 Hacker Way Building 14, First Floor Menlo Park, California, USA).',
];

export const PrivacyLinks = [
    { to: "declaration", text: "Deklaracja", },
    { to: "data", text: "Przekazywane dane", },
    { to: "period", text: "przechowywanie danych", },
    { to: "rights", text: "uprawnienia", },
    { to: "recipients", text: "odbiorcy", },
    { to: "cookies", text: "pliki cookies", },
    { to: "extinsions", text: "zastosowanie wtyczek", },
    { to: "changes", text: "zmiany polityki", },
    { to: "contact", text: "kontakt", },
];

export const ContestLinks = [
    { to: "1", text: "Postanowienia ogólne", },
    { to: "2", text: "Warunki uczestnictwa", },
    { to: "3", text: "Zadanie konkursowe", },
    { to: "4", text: "Nagroda", },
    { to: "5", text: "Reklamacje", },
    { to: "6", text: "Obowiązek informacyjny", },
    { to: "7", text: "Postanowienia końcowe", },
];

export const FAQLinks = [
    { to: "1", text: "Czy drzewa tlenowe mogą być wykorzystywane w miejskich środowiskach?", },
    { to: "2", text: "Czym tak właściwie jest drzewo tlenowe?", },
    { to: "3", text: "Jak szybko rośnie Paulownia?", },
    { to: "4", text: "Jakie są korzyści z uprawy drzew tlenowych?", },
    { to: "5", text: "Gdzie można uprawiać Paulownie?", },
    { to: "6", text: "Czy drzewo tlenowe może być wykorzystywane w celach komercyjnych?", },
    { to: "7", text: "Czy istnieją jakieś ograniczenia w uprawie Paulowni?", },
    { to: "8", text: "Czy istnieją badania naukowe potwierdzające skuteczność Paulowni w pochłanianiu CO2?", },
    { to: "9", text: "Czy istnieją inne zastosowania drzew tlenowych poza absorpcją CO2?", },
    { to: '10', text: 'Czy istnieją projekty lub inicjatywy związane z uprawą Paulowni na dużą skalę?' },
    { to: '11', text: 'Jakie są korzyści z posiadania drzew tlenowych?' },
    { to: '12', text: 'Czy istnieją specjalne warunki, które należy zapewnić dla drzew tlenowych?' },
    { to: '13', text: 'Czy drzewa tlenowe są łatwe w utrzymaniu?' },
    { to: '14', text: 'Czy istnieją badania naukowe potwierdzające korzyści zdrowotne z posiadania Paulowni?' },
    { to: '15', text: 'Czy istnieją jakieś specjalne techniki sadzenia lub pielęgnacji drzew tlenowych?' },
];

export const Plantations = [
    { img: img1, text: 'Naszą plantację paulowni rozpoczęliśmy tworzyć pod koniec lutego, kiedy zaczęliśmy przygotowywać 2-hektarową działkę. Pierwszym krokiem było postawienie solidnego ogrodzenia, które składa się z siatki leśnej i betonowych słupków o łącznej długości blisko 600 metrów. Ogrodzenie ma wysokość 1,8 metra, a słupki zostały osadzone na głębokości 60 centymetrów, co wymagało użycia świdra spalinowego do wykopania odpowiednich otworów.' },
    { img: img2, text: 'Po zakończeniu montażu płotu, przystąpiliśmy do instalacji bramy. Zdecydowaliśmy się skorzystać z usług lokalnej firmy specjalizującej się w montażu bram. Nasza brama ma szerokość 8 metrów, co zapewnia łatwy wjazd specjalistycznych maszyn na teren plantacji.' },
    { img: img3, text: 'W maju przyszedł czas na wyrównanie podłoża. Dzięki pomocy zaprzyjaźnionych rolników udało nam się przeprowadzić głęboką orkę i talerzowanie, ponieważ pole było wcześniej intensywnie uprawiane przez poprzedniego właściciela. Teren okazał się dość wilgotny, co oznaczało, że nie musieliśmy stosować stałego podlewania. Sadzonek nie podlewaliśmy regularnie – korzystaliśmy wyłącznie z naturalnych opadów deszczu, co świadczy o solidności naszych roślin.' },
    { img: img4, text: 'W drugiej połowie maja przystąpiliśmy do planowania rozmieszczenia drzew na plantacji. Na podstawie naszych doświadczeń z plantacji doświadczalnych, postanowiliśmy zastosować rozstaw 4x4 metry, a co drugi rząd przesunęliśmy o połowę tej odległości względem pierwszego, co pozwoliło na bardziej optymalne wykorzystanie przestrzeni. Naniesienie miejsc sadzenia było bardzo proste – użyliśmy do tego zwykłych sznurków, wyznaczając kolejne punkty.' },
    { img: img5, text: 'Sadzenie rozpoczęliśmy tuż po wyznaczeniu punktów. Trwało to około trzech dni. Proces polegał na wykopaniu odpowiednio głębokich otworów za pomocą świdra, włożeniu sadzonki, dokładnym dociśnięciu ziemi wokół niej oraz utworzeniu małego kopca, który zatrzymywałby wodę. Na koniec każda sadzonka została podlana. Na naszej plantacji posadziliśmy łącznie 1260 sadzonek. Dodatkowo, pod koniec czerwca, czyli trzy tygodnie po posadzeniu, zastosowaliśmy nawozy azotowe w postaci Azofoski, a 1 września wprowadziliśmy nawozy jesienne – fosfor i potas.' },
    { img: img6, text: 'Warto podkreślić, że wszystkie sadzonki się przyjęły, co zawdzięczamy ich dwuletniemu systemowi korzeniowemu. Sadzonki, które sadzimy i sprzedajemy, mają za sobą jedno zimowanie, co czyni je wyjątkowo odpornymi. Obecnie największe sadzonki, trzy miesiące po posadzeniu, osiągają już wysokość dwóch metrów, podczas gdy przeciętne mają ponad metr. Wkrótce ich łodygi zdrewnieją, a liście obeschą i opadną. Na początku przyszłego roku czeka nas pierwsze cięcie techniczne, które wzmocni system korzeniowy i pozwoli na wypuszczenie nowych, silniejszych pędów – to właśnie one staną się początkiem przyszłych, dorodnych drzew.' },
];